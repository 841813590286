.table-report {
	height: 100%;
	margin-bottom: 50px;
}

.table-report-wrapper {
	padding-bottom: 20px;

	.table-report-wrapper-loading {
		padding-top: 250px;
	}

	.string-value {
		max-width: 60px;
	}

	.sp-table{
		min-width: max-content;
	}
}

.topToolbar {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	max-width: 520px;

	& > div{
		width: 100%;
	}
}

.firstRow {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-bottom: 10px;
}

.configurations{
	display: flex;
	flex-direction: column;
	gap: 10px;

	.sp-dropdown{
		min-width: unset;
		max-width: unset;
		width: 100%;
	}
}

.phaseButtons {
	margin-right: 30px;
	margin-left: 5px;
}

.engine1 {
	.number-value {
		color: #5470c6 !important;
	}
	color: #5470c6 !important;
}

.engine2 {
	.number-value {
		color: #95ce7a !important;
	}
	color: #95ce7a !important;
}

.item-conf{
	display: flex;
	align-items: center;
	gap: 10px;

	span{
		font-size: 9px;
	}
}

@media(max-width: 520px){
	.topToolbar{
		.firstRow{
			max-width: 100%;
			display: flex;
			flex-wrap: wrap;
			gap: 16px;

			& > * {
				margin: 0px !important;
			}

			& > .button-group{
				display: flex;
				flex-wrap: nowrap;
			}
		}
	}
}