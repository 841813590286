.Theme_color_gpnDefault {

	$danger: rgb(205, 65, 65);
	$warning: #ff9900;
	$info: #2784ff;

	--white: #fff;

	--slate-100: #f1f5f9;
	--slate-200: #e2e8f0;
	--slate-400: #94a3b8;
	--slate-500: #64748b;
	--slate-700: #334155;
	--slate-800: #1e293b;

	--blue-500: #3b82f6;

	--warn-message-bg: #fffcf0;

	--danger: #{$danger};
	--warning: #{$warning};
	--info: #{$info};
	--primary: #2784ff;

}
